import React, {useEffect, useState} from 'react';
import PersistentDrawerLeft from '../../components/molecules/Drawer/Drawer';
import { Typography, Chip, ListItem, ListItemText } from '@material-ui/core';
import './index.css';
import { connect } from 'react-redux';
import {getProject} from '../../actions/project_actions';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: 120,
    padding: 20,
    height: '100%',
    background: '#23CFD5'
  },
  text: {
    color: 'white',
  },
  icon: {
    color: 'white',
  },
  card: {
    borderRadius: 2,
    boxShadow: '0 1px 2px rgba(0,0,0,.3), 0 0 0 1px rgba(63,63,68,.1)',
    background: '#fff',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 100,
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    overflowY: 'auto',
    position: 'relative',
    maxWidth: 250,
    height: 'calc(100vh - 10vh)'
  }
});
  
const DashboardProject = ({getProject, match, project}) => {

  const classes = useStyles();
  const [NodeShowProperties, setNodeShowProperties] = useState();

  useEffect(() => {
    if (match && match.params)
    {
      getProject(match && match.params.id);  
    }
  }, [getProject, match]);

  return (
    <React.Fragment>
      <div style={{display: 'flex', height: '100vh'}}>
        <div style={{width: '80vw'}}>
          <PersistentDrawerLeft setNodeShowProperties={setNodeShowProperties} style={{zIndex: 3}}></PersistentDrawerLeft>
          <div className="bg"></div>
        </div>
        <div style={{zIndex: 5, background: '#F4F4F4', boxShadow: '3px 0px 6px black', width: '20vw'}}>
          <div style={{borderBottom: '1px solid #ddd'}}>
            <Typography style={{color: '#9e9e9e'}} variant="h6" align="center">
              {<Chip style={{marginTop: 10, marginBottom: 10, textAlign: 'center'}} label={NodeShowProperties && NodeShowProperties.label ? NodeShowProperties.label : 'ENTITY'}/> }
            </Typography>
          </div>
          <div style={{padding: 10}}>
            <div className={classes.card}>
              {/* {project && project.properties.map((content, i) => <div style={{textAlign: 'center'}} key={i}>
                <Chip style={{marginTop: 10, marginBottom: 10}} label={`Node ${i + 1}`}/>
                <p>{content.label} | {content.quantityIn} | {content.quantityOut} | {content.unity}</p>
              </div>
              )} */}
              {NodeShowProperties && 
              project && project.properties.map((content, i) => content.node_id === NodeShowProperties.id && <div style={{textAlign: 'center'}} key={i}>
                {/* <Chip style={{marginTop: 10, marginBottom: 10}} label={NodeShowProperties.label}/> */}
                <ListItem button divider>
                  <ListItemText primary="Resources" />
                </ListItem>
                <h4>{content.label} | {content.quantityIn} | {content.quantityOut} | {content.unity}</h4>
              </div>)
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

DashboardProject.propTypes = {
  getProject: PropTypes.func,
  project: PropTypes.object
};

const mapStateToProps = () => state => ({
  project: state.projects.project
});

export default withRouter(connect(mapStateToProps, {getProject})(DashboardProject));