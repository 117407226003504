import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {disconnect} from '../../../actions/login_actions';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

const styles = makeStyles((theme) => ({
  background: {
    background: theme.palette.primary.main // or theme.palette.primary.main
  },
  titleConnect: {
    color: 'white',
    display: 'flex',
    padding: 5,
    alignItems: 'center',
    margin: 'unset',
    marginBottom: 1,
  },
  header: {
    marginLeft: 10,
  }
}));

const AuthenticatedTopBar = ({disconnect, me}) => {

  const classes = styles();

  return (
    <div>
      <div className={classes.background}>
        <Grid container align={'flex-end'} justify="space-between" alignItems="center" direction="row">
          <Typography className={classes.header} style={{color: 'white'}}>
              Bonjour {me && me.fullName}
          </Typography>
          <Button onClick={() => disconnect()} className={classes.titleConnect} color="secondary" variant="contained" >
            <Typography variant="inherit">
        Disconnect
            </Typography>
            <ExitToAppIcon/>
          </Button>
        </Grid>
      </div>
    </div>
  );
};

AuthenticatedTopBar.propTypes = {
  disconnect: PropTypes.func,
  me: PropTypes.object,

};

const mapStateToProps = () => state => ({
  me: state.me,
});

export default connect(mapStateToProps, {disconnect})(AuthenticatedTopBar);