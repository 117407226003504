import React from 'react';
import PropTypes from 'prop-types';
// import { I18n } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';

const propTypes = {
  classes: PropTypes.object.isRequired,
  pristine: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

const styles = theme => ({
  button: {
    marginTop: theme.spacing(2),
  },
});

const Submit = ({ classes, pristine, submitting, children }) => (
  <Button
    type="submit"
    variant="contained"
    color="primary"
    className={classes.button}
    disabled={pristine || submitting}
    size="large"
    fullWidth
  >
    <Typography style={{color: 'white'}}>
      {children || 'Sauvegarder'}
    </Typography>
  </Button>
);

export default withStyles(styles)(Submit);

Submit.propTypes = propTypes;
