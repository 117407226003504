import {
  GET_ME_TENTATIVE,
  GET_ME_SUCCESS,
  GET_ME_FAIL
} from '../actions/types';
    
const initialState = {
  me: null,
};
  
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ME_TENTATIVE: {
      return {
        ...state
      };
    }
    case GET_ME_SUCCESS: {
      return action.me;
    }
    case GET_ME_FAIL: {
      return {
        //   signedIn: false,
      };
    }
    default:
      return state;
  }
}
  