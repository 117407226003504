import { apiRoutes } from '../helpers/apiRoutes';
import Api from '../helpers/Api';

const projectsApi = {
  getAllProjects() {
    let route = apiRoutes.users.getAllProjects;
    return Api.get(route);
  },
  getProject(id) {
    let route = apiRoutes.users.getProject.replace('{id}', id);
    return Api.get(route);
  },
};

export default projectsApi;
