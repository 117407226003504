import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import image2 from '../../../assets/images/image17.png';
import PropTypes from 'prop-types';
import image from '../../../assets/images/image16.png';
import Checkbox from '@material-ui/core/Checkbox';
import Graphic from '../Graph/Graph';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { connect } from 'react-redux';
import EditIcon from '@material-ui/icons/Edit';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    zIndex: 6,
    height: '100%'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    zIndex: 6,
    left: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  ListItem: {
    color: 'white',
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#271d2c',
    overflow: 'hidden'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    zIndex: 5,
    flexGrow: 1,
    color: 'white',
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    // zIndex: 6,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: 'unset',
    marginLeft: 0,
  },
}));

const PersistentDrawerLeft = ({project, setNodeShowProperties}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openToggle, setOpenToggle] = React.useState(true);
  const [checkTags, setCheckTags] = React.useState([]);

  const handleToggle = () => {
    setOpenToggle(!openToggle);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <IconButton
        aria-label="open drawer"
        onClick={handleDrawer}
        edge="start"
        style={{position: 'absolute'}}
        className={clsx(classes.menuButton, open && classes.hide)}
      >
        <MenuIcon />
      </IconButton>
      <img alt="#" style={{zIndex: 10, left: 45,position: 'absolute', width: '250px'}} src={image2}/>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <img alt="#" style={{width: 'inherit'}} src={image}/>
        <div className={classes.drawerHeader}>
          <span className={classes.ListItem} onClick={handleDrawer}>Fermer la barre latérale</span>
          <IconButton style={{color: 'white'}} onClick={handleDrawer}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />

        <ListItem className={classes.ListItem} button >
          <ListItemIcon>
            <FileCopyIcon  style={{ color: 'white' }}  />
          </ListItemIcon>
          <ListItemText primary={'Fichier'} />
        </ListItem>
        <ListItem className={classes.ListItem} button>
          <ListItemIcon>
            <EditIcon  style={{ color: 'white' }}  />
          </ListItemIcon>
          <ListItemText primary={'Edition'} />
        </ListItem>
        <ListItem className={classes.ListItem} button onClick={handleToggle}>
          <ListItemIcon>
            <InboxIcon style={{ color: 'white' }} />
          </ListItemIcon>
          <ListItemText primary={'Filtres'} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse className={classes.ListItem} style={{padding: 8, background: '#413348'}} in={openToggle} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {project && project.tags && project.tags.map((content, i) => <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}} key={i}>
              <Checkbox
                // defaultChecked
                color="secondary"
                id={i.toString()}
                name={content}
                onClick={element => {!checkTags.includes(element.target.name) ? setCheckTags(content => [...content, element.target.name]) : setCheckTags(content => [...content.filter(v => v !== element.target.name)]);}}
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
              {content[0].toUpperCase() + content.substring(1)}
            </div>)}
          </List>
        </Collapse>
        <Divider />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <Graphic setNodeShowProperties={setNodeShowProperties} checkTags={checkTags}></Graphic>
      </main>
    </div>
  );
};

PersistentDrawerLeft.propTypes = {
  children: PropTypes.any,
  project: PropTypes.object,
  setNodeShowProperties: PropTypes.func
};

const mapStateToProps = () => state => ({
  project: state.projects.project
});

export default connect(mapStateToProps, undefined)(PersistentDrawerLeft);