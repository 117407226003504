import {
  GET_PROJECT_TENTATIVE,
  GET_PROJECT_SUCCESS,
  GET_ONE_PROJECT_SUCCESS
} from '../actions/types';

const initialState = {
};
  
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_PROJECT_TENTATIVE: {
      return {
        ...state
      };
    }
    case GET_PROJECT_SUCCESS: {  
      // return state = action.payload;
      return {
        projects: action.payload
      };
    }
    case GET_ONE_PROJECT_SUCCESS: {  
      return {
        ...state,
        project: action.payload
      };
    }
    default:
      return state;
  }
}
  