import React from 'react';
import MuiTextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';

const TextField = ({ input, label, meta: { touched, error }, ...custom }) => {
  
  return (
    <MuiTextField
      label={label}
      error={error && touched}
      helperText={touched && error}
      fullWidth
      variant={'filled'}
      margin="normal"
      {...input}
      {...custom}
    />
  );
};

TextField.propTypes = {
  input: PropTypes.any,
  label: PropTypes.any,
  meta: PropTypes.any,
};


export default TextField;
