import React, {useEffect, useRef, useState} from 'react';
import './index.css';
import { DataSet, Network} from 'vis-network/standalone/esm/vis-network';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Graphic = ({checkTags, project, setNodeShowProperties}) => {

  const [Nodes, setNodes] = useState();
  const [Edges, setEdges] = useState();

  const domNode = useRef(null);
  const network = useRef(null);

  useEffect(() => {
    if (checkTags)
    {
      // ==> OLD WAY ES5 SYNTAX
      //   nodes.forEach(function (_node) {
      //     var _isHighlight = false;
      //     if (checkTags.length > 0)
      //         _isHighlight = checkTags.every(function (_tag) {
      //             return _node.tags != undefined && _node.tags.indexOf(_tag) > -1 ? true : false;
      //         });
      //     _node.color = _isHighlight === true ? {background: '#ff0000aa', border: 'red'} : null;

      //     nodes.update(_node);
      // });
      
      let arrayIdToChange = nodes.map((contentNode) => contentNode.tags && contentNode.tags.some(r => checkTags.includes(r)) && contentNode.id);
      let arrayFinal = arrayIdToChange.filter(v => v);
      arrayFinal.map((content) => {
        nodes.update({id: content, color: 'red'});
      });
    }
  });

  useEffect(() => {
    if (project) 
    {
      project.nodes.forEach(function(v){ delete v.image; });
      setNodes(project.nodes);
      setEdges(project.links);
    }

  }, [project]);
  
  // An array of nodes
  const nodes = new DataSet(Nodes);
  const edges = new DataSet(Edges);

  const data = {
    nodes,
    edges
  };

  const options = {
    height: '100vh',
    width: '100%',
    autoResize: true,
  };

  // HANDLE GRAPH (RE)DRAW AND HANDLE EVENT

  useEffect(
    () => {
      network.current = new Network(domNode.current, data, options);
      network.current.on( 'click', function(properties) {
        let ids = properties.nodes;
        let clickedNodes = nodes.get(ids);
        if (clickedNodes.length > 0)
          setNodeShowProperties({id: clickedNodes[0].id, label: clickedNodes[0].label});
      });
    },
    [Nodes, data]
  );

  // =======================================

  return (
    <div style={{height: '100%'}}>
      {/* <button onClick={() => handleColorNode()}>METTRE A JOUR LA COULEUR</button> */}
      <div style={{height: '100%'}} ref = { domNode } />
    </div>
  );
};


Graphic.propTypes = {
  checkTags: PropTypes.array,
  project: PropTypes.object,
  setNodeShowProperties: PropTypes.func,
};

const mapStateToProps = () => state => ({
  project: state.projects.project,
});

export default connect(
  mapStateToProps,
  null,
)(Graphic);

