import {
  GET_PROJECT_TENTATIVE,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAIL,
  GET_ONE_PROJECT_SUCCESS,
} from './types';
  
import projectsApi from '../api/projects_api';

export const getAllProjects = () => dispatch => {
  dispatch({ type: GET_PROJECT_TENTATIVE });
  projectsApi
    .getAllProjects()
    .then(
      res => {
        let data = res.data;
        dispatch({
          type: GET_PROJECT_SUCCESS,
          payload: data,
        });
      })
    .catch(error => dispatch({ type: GET_PROJECT_FAIL, error }));
};

export const getProject = id => dispatch => {
  dispatch({ type: 'GET/ONE/PROJECT' });
  projectsApi
    .getProject(id)
    .then(
      res => {
        let data = res.data;
        dispatch({
          type: GET_ONE_PROJECT_SUCCESS,
          payload: data,
        });
      })
    .catch(error => dispatch({ type: 'GET_PROJECT_FAIL', error }));
};
  