import React from 'react';
import './App.css';
import RouterJS from '../modules/Router';
import { createBrowserHistory } from 'history';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const history = createBrowserHistory();

const App = () => {
  return (
    <div className="core">
      <RouterJS history={history}/>
    </div>
  );  
};

const mapStateToProps = () => state => ({
  signedIn: state.login.signedIn,
});

App.propTypes = {
  signedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, undefined)(App);