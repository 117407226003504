import env from '../env';

export const apiUrl = env.apiUrl;

export const apiRoutes = {
  users: {
    login: apiUrl + '/login_check',
    getAll: apiUrl + '/users',
    getMe: apiUrl + '/user/me',
    register: apiUrl + '/users',
    getAllProjects: apiUrl + '/projects',
    getProject: apiUrl + '/project/{id}'
  },
};
