import React from 'react';

const ErrorPage = () => {

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <p>Page 404 - Non trouvé</p>
    </div>
  ); 
};

export default ErrorPage;