import React from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import RegisterForm from '../../../forms/RegisterForm';
import { createSessionToken } from '../../../actions/login_actions';
import PropTypes from 'prop-types';

// const styles = theme => ({
//   title: {
//     marginTop: '1em',
//     marginBottom: '0.5em',
//   },
//   paper: {
//     ...paper(theme),
//     paddingBottom: theme.spacing(4),
//   },
// });

const Register = (props) => {

  const handleSubmit = values => {
    const { createSessionToken } = props;
    createSessionToken(values);
  };

  return (
    <Grid item xs={12} sm={12} lg={12} style={{ margin: 'auto' }}>
      <RegisterForm
        onSubmit={handleSubmit}
      />
    </Grid>
  );  };

Register.propTypes = {
  createSessionToken: PropTypes.func,
};

const mapStateToProps = ({ session }) => ({
  session,
});

export default connect(
  mapStateToProps,
  { createSessionToken })(Register);

