import React from 'react';
import Image2 from '../../../assets/images/logo2.png';

const Header = () => {
  return (
    <div style={{height: 200, backgroundColor: '#F5F5F5', marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'flex-end'}}>
      <img  alt={'#'} src={Image2}/>
    </div>
  );
};

export default Header;