import React, {useState} from 'react';
import Card from '@material-ui/core/Card';
import { Typography, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { connect } from 'react-redux';
import Modal from '../../components/molecules/Modal';
// import PropTypes from 'prop-types';
// import {getAllUsers} from '../../actions/user_actions';


const useStyles = makeStyles({
  root: {
    maxWidth: 200,
    padding: 20,
    background: '#23CFD5'
  },
  text: {
    color: 'white',
  },
  icon: {
    color: 'white',
  }
});
  
const Home = () => {

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };


  // ==> Comment function to getAllUsers when /home launch or getMe in future
  // useEffect(() => {getAllUsers();}, [getAllUsers]);
  

  const classes = useStyles();

  return (
    <div>
      <Grid container item align="center" justify="space-around">
        <Grid container item xs={6} sm={8} md={10} lg={6} justify="space-around">
          {/* <Card className={classes.root} variant="outlined" onClick={handleClickOpen}>
            <CardContent>
              <Typography className={classes.text}>
              Supprimer un projet
              </Typography>
              <AssignmentIcon className={classes.icon} fontSize="large"/>
            </CardContent>
          </Card> */}
          <Card className={classes.root} variant="outlined" onClick={handleClickOpen}>
            <CardContent>
              <Typography className={classes.text}>
              Ouvrir un projet que je viens de tester
              </Typography>
              <AssignmentIcon className={classes.icon} fontSize="large"/>
            </CardContent>
          </Card>
          {/* <Card className={classes.root} variant="outlined" onClick={handleClickOpen}>
            <CardContent>
              <Typography className={classes.text}>
              Créer un projet
              </Typography>
              <AssignmentIcon className={classes.icon} fontSize="large"/>
            </CardContent>
          </Card> */}
        </Grid>
      </Grid>
      {/* <Graphic/> */}

      {open ? ReactDOM.createPortal(<Modal open={open} onClose={handleClose}/>, document.body) : null}
    </div>
  );
};

// Home.propTypes = {
//   getAllUsers: PropTypes.func,
// };

export default connect(undefined)(Home);
