

import React from 'react';
import Image from '../../../assets/images/logopartners.png';

const Footer = () => {
  return (
    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
      <img  alt={'#'} src={Image} />
    </div>

  );
};

export default Footer;
