import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

// import { withStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import TextField from '../components/molecules/TextField';
import { reduxForm, Form, Field } from 'redux-form';
import Submit from '../components/molecules/Submit';
import PropTypes from 'prop-types';

// const useStyles = makeStyles((theme) => ({
//   input: {
//     padding: 10,
//   },
// }));


const LoginForm = (props) => {
  
  // const classes = useStyles();
  const { submitting, handleSubmit, onSubmit} = props;

  return (
    <Grid item lg={12} style={{margin: '0 auto'}}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="email"
          label="Email"
          // className={classes.input}
          component={TextField}
          // placeholder="Email"
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Field
          name="password"
          type="password"
          component={TextField}
          label="Password"
          // className={classes.input}
          // placeholder="Mot de passe"
          //   label={I18n.t('forms.story.spotName.label')}
          required
        />
        <Submit
          // pristine={pristine && !session}
          submitting={submitting}
          // className={classes.input}
        >
          Login
        </Submit>
      </Form>
    </Grid>
  );
};

LoginForm.propTypes = {
  submitting: PropTypes.any,
  handleSubmit: PropTypes.any,
  onSubmit: PropTypes.any,
};

export default reduxForm({
  form: 'LoginForm',
})(LoginForm);