import { apiRoutes } from '../helpers/apiRoutes';
import Api from '../helpers/Api';

const sessionApi = {
  login(session) {
    let route = apiRoutes.users.login;
    return Api.post(route, session);
  },
};

export default sessionApi;
