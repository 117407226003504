import { createMuiTheme } from '@material-ui/core/styles';
// import purple from '@material-ui/core/colors/purple';
// import green from '@material-ui/core/colors/green';
// import cyan from '@material-ui/core/colors/green';
import 'fontsource-montserrat';

const fontFamily = 'Montserrat';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#23CFD5',
    },
    // secondary: {
    //   main: green[500],
    // },
  },
  typography: {
    fontFamily,
  },
});

export default theme;