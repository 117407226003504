import { createStore, applyMiddleware, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise';

import reducers from '../reducers';

const middleware = [promise, thunk];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

let store = applyMiddleware(...middleware)(createStore)(reducers);

if (process.env.NODE_ENV === 'development') {
  const composeEnhancer =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: 'redux-form',
      })) ||
    compose;

  store = composeEnhancer(applyMiddleware(...middleware))(createStore)(
    reducers,
  );
}

export default store;
