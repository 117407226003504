import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../../../forms/LoginForm';
import { createSessionToken, checkSession } from '../../../actions/login_actions';
import { Typography, CardContent, CardHeader } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { useLocation, useHistory} from 'react-router-dom';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Image2 from '../../../assets/images/logo2.png';
import './index.css';

const useStyles = makeStyles({
  header: {
    padding: 10,
  },
});

const Login = ({createSessionToken, checkSession, login}) => {
   
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    if (login.signedIn) {
      history.replace(from);
    }
  }, [login, from, history]);

  useEffect(() => {
    setTimeout(() => {
      checkSession();
    }, 1000);
  }, [checkSession]);

  if (!login.loaded) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', left: 0, background: '#ececec', position: 'fixed', top: 0, height: '100%', width: '100%', zIndex: 9999, overflow: 'auto' }}>
      <img alt="#" className="fadeIn" style={{width: 410}} src={Image2}/>
    </div>;
  }

  const handleSubmit = values => {
    createSessionToken(values);
  };

  return (
    <Grid item xs={12} sm={12} lg={12} style={{ margin: 'auto' }}>
      <Grid item xs={12} md={6} sm={8} lg={4} style={{ margin: 'auto' }}>
        <Card raised={true}>
          <Grid align="center">
            <CardHeader classes={{
              root: classes.header, // class name, e.g. `classes-nesting-root-x`
            }} title={'Se connecter'}/>
          </Grid>
          <CardContent>
            <LoginForm
              onSubmit={handleSubmit}
            />
          </CardContent>
        </Card>

        <Card raised={true} style={{marginTop: 20}}>
          <CardContent>
            <Typography align="center">
                Pas encore inscrit , ceci est un test? 
            </Typography>
            <Typography align="center">
                Cliquez ici pour vous <Link to={'/register'}>inscrire</Link>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

Login.propTypes = {
  createSessionToken: PropTypes.func,
  checkSession: PropTypes.func,
  login: PropTypes.object,
};

const mapStateToProps = ({ session }) => state => ({
  session,
  login: state.login,
});

export default connect(
  mapStateToProps,
  { createSessionToken, checkSession })(Login);


