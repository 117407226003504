import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogContent from '@material-ui/core/DialogContent';
import { Typography, DialogTitle, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FolderIcon from '@material-ui/icons/Folder';
import {getAllProjects} from '../../actions/project_actions';
import { useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
  },
  iconButton:
    {
      position: 'absolute',
      right: theme.spacing(2),
      top: theme.spacing(1)
    }
}));

const Modal = ({open, onClose, getAllProjects, projects}) => {

  const classes = useStyles();

  useEffect(() => {getAllProjects();}, [getAllProjects]);

  // classes={{
  //   root: classes.root, // class name, e.g. `classes-nesting-root-x`
  // }}

  return (
    <Dialog fullWidth={true} open={open} >
      <Grid align="center">
        <DialogTitle>Ouvrir un projet</DialogTitle>
        <IconButton aria-label="close" className={classes.iconButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent>
        <List>
          {
            projects && projects.map((content, i) => 
              <ListItem key={i}>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <Link to={`/dash/${content.id}`}>
                  <Typography>
                    {content.name}
                  </Typography>
                </Link>
              </ListItem>,
            )
          }
        </List>
        <DialogContentText>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getAllProjects: PropTypes.func,
  projects: PropTypes.array,
};

const mapStateToProps = () => state => ({
  projects: state.projects.projects,
});

export default withRouter(connect(mapStateToProps, {getAllProjects})(Modal));