import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AppRoute = (props) => {

  const { children, signedIn, ...rest } = props;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        signedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );

};

const mapStateToProps = () => state => ({
  signedIn: state.login.signedIn,
});

AppRoute.propTypes = {
  children: PropTypes.any,
  signedIn: PropTypes.bool.isRequired,
};

export default connect(
  mapStateToProps,
  null,
)(AppRoute);

