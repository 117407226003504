export const LOGIN_TENTATIVE = 'AUTH/LOGIN_TENTATIVE';
export const LOGIN_TENTATIVE_SUCCESS = 'AUTH/LOGIN_TENTATIVE_SUCCESS';
export const LOGIN_TENTATIVE_FAIL = 'AUTH/LOGIN_TENTATIVE_FAIL';
export const LOGOUT_REQUESTED = 'AUTH/LOGOUT_REQUESTED';
export const GET_PROJECT_TENTATIVE = 'PROJECTS/GET_PROJECT_TENTATIVE';
export const GET_PROJECT_SUCCESS = 'PROJECTS/GET_PROJECT_SUCCESS';
export const GET_PROJECT_FAIL = 'PROJECTS/GET_PROJECT_FAIL';
export const GET_ME_TENTATIVE = 'ME/GET_ME_TENTATIVE';
export const GET_ME_SUCCESS = 'ME/GET_ME_SUCCESS';
export const GET_ME_FAIL = 'ME/GET_ME_FAIL';
export const GET_ONE_PROJECT_SUCCESS = 'PROJECT/GET_ONE_PROJECT_SUCCESS';