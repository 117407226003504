import axios from 'axios';
import env from '../env';

// import store, { history } from '../store';
// import { forced_logout } from '../stores/auth';
// import env from '../env';

let authorizationInterceptor = undefined;
let notAuthorizedInterceptor = undefined;
export let params = {};


let config = {
  baseURL: env.apiUrl,
  headers: {
    'Accept': 'application/json'
  }
};

let Api = axios.create(config);

export const addAutorization = (token) => {
  authorizationInterceptor = Api.interceptors.request.use(config => {
    config.headers.Authorization = 'Bearer ' + token;
    return config;
  });
};

export const removeAuthorization = () => {
  Api.interceptors.request.eject(authorizationInterceptor);
  Api.interceptors.response.eject(notAuthorizedInterceptor);
};

export default Api;
